import React, { Component } from "react"
import Footer from "../components/base/Footer"
import Header from "../components/base/Header"
import SEO from "../components/base/seo"

const termsandcondition = {
  title1: `Arus Oil - Terms of Service`,
  paragraph1: `This document sets out the Terms and Conditions (“Terms”) on which the My Protech Sdn Bhd (1307580-A) (“My Protech”), having its registered office at IOI Rio Office Tower, Bandar Puteri Puchong, Selangor, Malaysia (“We”, “Us” or “Our”) provides you (“You” , “Your” or “User”) with access to ‘Arus Oil’ (the “App”). Please read these terms very carefully.`,
  paragraph2: `By creating an account through the website or otherwise using the App, you indicate your acceptance of these terms of service. Do not use this App or any of its content or services if you do not accept these terms of service. `,
  paragraph3: `These terms of service may be amended or updated by My Protech from time to time without notice and the terms of service may have changed since your last visit to this website. It is your responsibility to review these terms of service for any changes. Your use after any amendments or updates of these terms of service shall signify your assent to and acceptance of such revised terms. Any new features that may be added to this website from time to time will be subject to these terms of service, unless stated otherwise. Visit this page periodically to review these terms of service.`,
  paragraph4: `In order to use the services, you must register with us and provide certain information. For information on how we use your personal information, please see the Arus Oil privacy policy, the terms of which are incorporated by reference into this agreement. Do not use our App or services if you are not in agreement with our privacy policy.`,
  paragraph5: `Arus Oil App is a platform where “Seller(s)” can sell their Used Cooking Oil (“UCO”) to the “Buyer(s)”. Selling price will fluctuate according to global palm oil price and will be made known to all Sellers prior to checking out. Seller(s) must agree with the terms and conditions prior to acceptance of the offered price. Once an offer has been made, Buyer will be prompted on such offers and allowed to arrange for a logistical pick up of the UCO and payment will be made directly into the Seller’s bank account.`,
  definition: `Definitions`,
  paragraph6:
    "“App” refers to the object code form of the Arus Oil App(s), available on web browsers and downloadable through the Apple, Windows, Google Play and Amazon app stores. The Arus Oil App offers an online space that enables users ",
  users: `("Sellers")`,
  paragraph7: `to sell Used Cooking Oil `,
  usedcookingoil: `("UCO")`,
  paragraph8: `Users who assign pickups, publish UCO prices, and facilitate payment are `,
  buyer: `"Buyer"`,
  paragraph9: `and Users who schedule a pickup of UCO are `,
  sellers: `"Sellers"`,
  paragraph10: `Buyer monitors all appointments made and facilitates pick up with either an in-house party or a third party and facilitates payment directly to the Seller’s bank account through a third party payment service. UCO sold and all transactions made in the App are non-refundable. You must register an account to access and use features of the App and keep your account information accurate.`,
  title2: `Information about the Arus Oil App`,
  point1: `The App is owned and managed by My Protech. All information supplied through the service is managed by My Protech.`,
  point2: `My Protech can be contacted by writing to its registered office at IOI Rio Office Tower, Bandar Puteri Puchong, Selangor, Malaysia. `,
  point3: `The App is hosted and managed on Google Firebase and adheres to all terms and conditions as well as privacy policy set by Google Services.`,
  title3: `Use of the service`,
  paragraph11: `You must be at least 18 years old to register to the App as an User. Upon registration, you must provide your full name, email address, and additional information required. Any personal information provided by User to us will be stored in accordance with the Arus Oil Privacy Policy, which you should read carefully. We ask that you provide your real name when creating an account. You will be solely responsible and liable for any and all loss, damage, and additional costs that we or any other person may incur as a result of your submission of any false, incorrect or incomplete information or your failure to update your registration information as needed. Here are the commitments Users make relating to registration and maintaining the security of their account: `,
  sellerresp: `Seller’s responsibilities`,
  point4: `Provide accurate information during registration and keep such information up to date;`,
  point5: `Keep your user login details and access to the App secured. We will not be liable for any damages caused by virtue of the compromise of your account or password, including any unauthorised access to your account or use. Please contact us immediately if you suspect or become aware of any unauthorised use of your email login or password or any other breach of security`,
  point6: `Treat the other Users of the App with whom you interact with respect and not engage in any conduct which is threatening, harassing or otherwise harmful to other Users or third parties, or do anything that breaches the general User conduct guidelines listed below. We reserve the right to terminate your access to the App and take appropriate legal action if you are in breach of these requirements or the restrictions in the section below;`,
  point7: `Agree not to upload any content or create any User Groups or events that are harmful, abusive, constitute hate speech, or are harassing, discriminatory or otherwise objectionable and harmful to other Users and/or the public;`,
  point8: `If we disable a User’s account, they will not create another one without our permission.`,
  point9: `If you are accepting these Terms and using the Services on behalf of a company, organisation, government, or other legal entity, you represent and warrant that you are authorised to do so.`,
  point10: `Except as expressly permitted in these Terms, you shall not, and shall not permit others to: `,
  point11: `modify, translate, create derivative copies of or copy the App, in whole or in part;`,
  point12: `reverse engineer, decompile, disassemble or otherwise reduce the object code of the App to source code form;`,
  point13: `distribute, sub-licence, assign, share, timeshare, sell, rent, lease, grant a security interest in, use for service bureau purposes, or otherwise transfer the App or your right to use the App;`,
  point14: `remove or modify any copyright, trademark, or other proprietary notices belonging to us or our licensors contained within the App; or`,
  point15: `use the App in any manner not expressly authorised by these Terms.`,
  myprotechresp: `My Protech’s responsibilities `,
  point16: `We endeavour to provide the best service we can, but you understand and agree that the features, functionality, information and materials provided on or through this website are provided "as is." specifically, but without limitation, Arus Oil does not represent, warrant or offer any conditions that:`,
  point17: `The information or materials on this website are correct, accurate, reliable or complete;`,
  point18: `The functions contained on this website will be uninterrupted or error-free;`,
  point19: `Defects will be corrected; or`,
  point20: `This website or the technology(s) that makes it available are free of viruses or other harmful components. My Protech specifically disclaims all representations, warranties and conditions, either expressed, implied, statutory, by usage of trade, course of dealing or otherwise including but not limited to any implied warranties of merchantability, non-infringement, title, satisfactory quality or fitness for a particular purpose. Any information or material downloaded or otherwise obtained through the use of the website is at your own discretion and risk and you will be solely responsible for any damage to your computer system, loss of data, or any other loss that results from downloading or using any such material. My Protech does not warrant, endorse, guarantee, provide any conditions or representations, or assume any responsibility for any product or service advertised or offered by any third-party through the website or in respect to any website that can be reached from a link on the website or featured in any banner or other advertising on the website, and timecounts shall not be a party to any transaction that you may enter into with any such third-party.`,
  point21: `Under no circumstances shall My Protech be held liable for any indirect, incidental, special, consequential, exemplary or other damages whatsoever, including, without limitation, any indirect, incidental, special, consequential, exemplary or other damages that result from: `,
  point22: `Your use of or your inability to use this website or any of the information or materials contained on this App;`,
  point23: `The cost of procurement of substitute goods, data, information or services; `,
  point24: `Errors, mistakes, or inaccuracies in the materials on the website; `,
  point25: `Personal injury, death or property damage of any kind whatsoever arising from or relating to your use of the App; `,
  point26: `Any bugs, viruses, trojan horses, or any other files or data that may be harmful to computer or communication equipment or data that may have been transmitted to or through the website; or`,
  point27: `Any errors or omissions in any material on the website or any other loss or damage of any kind arising from or relating to your use of the App. These limitations shall apply even if My Protech has been advised of the possibility of such damages. The foregoing limitations shall apply to the fullest extent permitted by law.`,
  point28: `We reserve the right to make changes to the App or part thereof, from time to time including without limitation the removal, modification and/or variation of any elements, features and functionalities of the App.`,
  point29: `Without prejudice to our other rights and remedies, we reserve the right to temporarily or permanently suspend or disable your access to the App at any time without notice to you in the event you breach any of the provisions herein. `,
  point30: `In the event that we, in our sole discretion, consider that you are making any illegal and/or unauthorised use of the App, and/or your use of the App is in breach of these Terms, we reserve the right to take any action that we deem necessary, including terminating without notice your use of the App and, in the case of illegal use, instigating legal proceedings. `,
  title4: `Membership`,
  point31: `Your App membership is free and will continue from a month-to-month cycle until termination. `,
  point32: `We may offer promotions offered by third parties in conjunction with the provision of their own products and services. We are not responsible for the products and services number of membership plans, including special promotional plans or memberships provided by such third parties.`,
  title5: `Use of the App`,
  point33: `Where you access the App, you acknowledge and agree that:`,
  point34: `My Protech is in no way linked, connected or affiliated with Apple, Amazon, Google Play, Windows or Android; `,
  point35: `Access to and use of the App may be limited by your network carrier and will be limited if you are attempting to access the App from outside the area of your network carrier; `,
  point36: `You acknowledge you will not be able to access and use certain functionalities of the App unless you have internet access through a GPRS, 3G or Wi-Fi connected mobile device. All traffic charges or access charges incurred due to the use of the App are subject to your agreed terms with your mobile network provider; `,
  point37: `Neither your airtime provider nor any app store or kiosk or kiosk operator (including but not limited to Google Play App Store, Apple AppStore, Amazon and Windows Phone Marketplace) shall bear any responsibility or liability whatsoever in relation to sale, distribution, functionality, accessibility, support or performance or non-performance of the App; `,
  point38: `Your airtime provider and any app kiosk or kiosk operator (including but not limited to Google Play App Store, Apple AppStore, Amazon and Windows Phone Marketplace) are third-party beneficiaries in respect of this clause; `,
  point39: `My Protech is solely responsible for providing any support and maintenance in respect of the App; and you will comply with any applicable third-party terms and conditions in your use of the App. `,
  title6: `Terms and Termination `,
  paragraph12: `These Terms will remain in full force and effect while you use the App. We may, at any time and for any reason, terminate these Terms with you and deny you access to the App. In the event of termination of these Terms for any reason, you must not attempt to use the App. `,
  title7: `Intellectual Property `,
  paragraph13: `Other than in relation to any links to third-party websites, My Protech owns or have a licence to use all rights, titles and interest in and to the App, including without limitation all copyright and any other intellectual property rights therein. These Terms shall not be construed to convey title to or ownership of the App or the content contained therein to you. All rights in and to the App or content not expressly granted to you are reserved by us. You are expressly prohibited from removing, modifying, altering or using any registered or unregistered marks/logos owned by us, and doing anything which may be seen to take unfair advantage of our reputation and goodwill or could be considered an infringement of any of the intellectual property rights owned and/or licensed to us, without first obtaining written permission from My Protech. `,
  title8: `Warranties`,
  paragraph14: `Any content, information or material provided to you as part of the App is provided on an ‘as is’ basis for your convenience only. Such content, information and material does not constitute advice or a recommendation and therefore it should not be solely relied on to assist in making or refraining from making a decision, or to assist in deciding on a course of action. To the maximum extent permitted by law, we expressly exclude all representations, warranties, obligations and liabilities in connection with the App, and any content, information or materials provided therein. `,
  title9: `Liability`,
  point40: `Use of the App is provided free of charge. `,
  point41: `We shall not be responsible for any: `,
  point42: `losses, damages, costs and expenses which were not reasonably foreseeable to be incurred by you and could not have been reasonably foreseeable by you or us on entering these Terms; `,
  point43: `or loss of profits, contracts or business, loss of an anticipated outcome or anticipated savings. `,
  title10: `Data Protection and Privacy `,
  point44: `We are committed to protecting (i) your privacy; and (ii) the confidentiality of the information provided by you using the App. For the purposes of the Data Protection Act 1988, the data controller is the My Protech Sdn Bhd (1307580-A), having its registered office at IOI Rio Office Tower, Bandar Puteri Puchong, Selangor, Malaysia `,
  point45: `Where you provide any personal information through the App, for example where you register and set up an account, we shall use that information for the following purposes: `,
  point46: `to assist in providing you with the features and services in the App; `,
  point47: `to inform you about changes to the App; `,
  point48: `and to inform you about additional content and features of the App. `,
  point49: `We may disclose your personal information to third parties affiliated with the event (i.e. providing information to third parties for verification purposes). `,
  point50: `All information you provide to us is stored in our secure servers. By submitting your personal data, you agree to this transfer and/or storing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with data protection principles. Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our App; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access. `,
  title11: `General`,
  point51: `You may print and keep a copy of these Terms, which form the entire agreement between you and us and supersedes any other communications or advertising with respect to the App. `,
  point52: `These Terms may only be modified with our prior written consent. We may alter or amend these Terms at any time, with immediate effect and without notice. By continuing to use the App after such alteration, you will be deemed to have accepted any amendment to these Terms. `,
  point53: `These Terms and their performance shall be governed by and construed in accordance with the laws of Malaysia and the parties hereby submit to the non-exclusive jurisdiction of the courts of Malaysia. `,
  point54: `You shall comply with all foreign and local laws and regulations which apply to your use of the App in whatever country you are physically located, including without limitation, consumer law, export control laws and regulations. `,
  point55: `You agree that because of the unique nature of the App, and our proprietary rights therein, a demonstrated breach of these Terms by you would irreparably harm us and monetary damages would be inadequate compensation. Therefore, you agree that we shall be entitled to preliminary and permanent injunctive relief, as determined by any court of competent jurisdiction to enforce the provisions of these Terms. `,
  point56: `If any provision of these Terms is declared void, illegal, or unenforceable, the remainder of these Terms will be valid and enforceable to the extent permitted by applicable law. In such an event, the parties agree to use their best efforts to replace the invalid or unenforceable provision by a provision that, to the extent permitted by the applicable law, achieves the purposes intended under the invalid or unenforceable provision. `,
  point57: `Any failure by any party to these Terms to enforce at any time any term or condition under these Terms will not be considered a waiver of that party's right thereafter to enforce each and every term and condition of these Terms. `,
  point58: `Neither party will be responsible for delays resulting from circumstances beyond the reasonable control of such party, provided that the non-performing party uses reasonable efforts to avoid or remove such causes of non-performance and continues performance hereunder with reasonable dispatch whenever such causes are removed. `,
  point59: `Nothing in these Terms shall give, directly or indirectly, any third-party any enforceable benefit or any right of action against us and such third parties shall not be entitled to enforce any term of these Terms against us. `,
  point60: `If you feel that any materials appearing on the App are offensive, objectionable or potentially defamatory please contact us providing full details of the nature of your complaint and the materials to which the complaint relates. `,
}

export default class Tutorial extends Component {
  render() {
    return (
      <div className="min-h-screen bg-gray-100">
        <SEO
          title="Arus Oil - Terms and Conditions"
          description="This page sets out the Terms and Conditions (“Terms”) on which the My Protech Sdn Bhd (1307580-A) (“My Protech”), having its registered office at IOI Rio Office Tower, Bandar Puteri Puchong, Selangor, Malaysia (“We”, “Us” or “Our”) provides you (“You” , “Your” or “User”) with access to ‘Arus Oil’ (the “App”)"
        />
        <Header page="Terms and Conditions" />
        <header>
          <div className="pt-14 max-w-7xl flex mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-warm-gray-900">
              {termsandcondition.title1}
            </h1>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="block px-4 py-8 sm:px-0">
              <div className="flex flex-col mt-2">
                <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                  <div className="bg-white px-4 py-3 flex flex-col items-center justify-between border-t border-gray-200 sm:px-6">
                    <div className="relative max-w-7xl text-justify pt-6 pb-16 sm:px-6">
                      <p className="mt-2 text-sm">
                        {termsandcondition.paragraph1}
                        <br />
                        <br />
                        {termsandcondition.paragraph2}
                        <br />
                        <br />
                        {termsandcondition.paragraph3}
                        <br />
                        <br />
                        {termsandcondition.paragraph4}
                        <br />
                        <br />
                        {termsandcondition.paragraph5}
                        <br />
                        <br />
                        <br />
                        <span className="font-bold text-xl">
                          {termsandcondition.definition}
                        </span>
                        <br />
                        <br />
                        {termsandcondition.paragraph6}
                        <span className="font-bold">
                          {termsandcondition.users}
                        </span>{" "}
                        {termsandcondition.paragraph7}
                        <span className="font-bold">
                          {termsandcondition.usedcookingoil}
                        </span>
                        {". "}
                        {termsandcondition.paragraph8}
                        <span className="font-bold">
                          {termsandcondition.buyer}
                        </span>{" "}
                        {termsandcondition.paragraph9}
                        <span className="font-bold">
                          {termsandcondition.sellers}
                        </span>
                        {". "}
                        {termsandcondition.paragraph10}
                      </p>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title2}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point1}</li>
                        <li>{termsandcondition.point2}</li>
                        <li>{termsandcondition.point3}</li>
                      </ul>

                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title3}
                      </h2>
                      <p className="mt-2 text-sm">
                        {termsandcondition.paragraph11}
                      </p>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.sellerresp}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point4}</li>
                        <li>{termsandcondition.point5}</li>
                        <li>{termsandcondition.point6}</li>
                        <li>{termsandcondition.point7}</li>
                        <li>{termsandcondition.point8}</li>
                        <li>{termsandcondition.point9}</li>
                        <li>
                          {termsandcondition.point10}
                          <ul className="ml-4 list-decimal">
                            <li>{termsandcondition.point11}</li>
                            <li>{termsandcondition.point12}</li>
                            <li>{termsandcondition.point13}</li>
                            <li>{termsandcondition.point14}</li>
                            <li>{termsandcondition.point15}</li>
                          </ul>
                        </li>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.myprotechresp}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point16}</li>
                        <ul className="ml-4 list-decimal">
                          <li>{termsandcondition.point17}</li>
                          <li>{termsandcondition.point18}</li>
                          <li>{termsandcondition.point19}</li>
                          <li>{termsandcondition.point20}</li>
                        </ul>
                        <li>{termsandcondition.point21}</li>
                        <ul className="ml-4 list-decimal">
                          <li>{termsandcondition.point22}</li>
                          <li>{termsandcondition.point23}</li>
                          <li>{termsandcondition.point24}</li>
                          <li>{termsandcondition.point25}</li>
                          <li>{termsandcondition.point26}</li>
                          <li>{termsandcondition.point27}</li>
                        </ul>
                        <li>{termsandcondition.point28}</li>
                        <li>{termsandcondition.point29}</li>
                        <li>{termsandcondition.point30}</li>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title4}
                      </h2>
                      <ul className="ml-4 list-disc text-sm">
                        <li>{termsandcondition.point31}</li>
                        <li>{termsandcondition.point32}</li>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title5}
                      </h2>
                      <p className="mt-2 text-sm">
                        {termsandcondition.point33}
                      </p>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point34}</li>
                        <li>{termsandcondition.point35}</li>
                        <li>{termsandcondition.point36}</li>
                        <li>{termsandcondition.point37}</li>
                        <ul className="ml-4 list-decimal">
                          <li>{termsandcondition.point38}</li>
                          <li>{termsandcondition.point39}</li>
                        </ul>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title6}
                      </h2>
                      <p className="mt-2 text-sm">
                        {termsandcondition.paragraph12}
                      </p>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title7}
                      </h2>
                      <p className="mt-2 text-sm">
                        {termsandcondition.paragraph13}
                      </p>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title8}
                      </h2>
                      <p className="mt-2 text-sm">
                        {termsandcondition.paragraph14}
                      </p>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title9}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point40}</li>
                        <li>{termsandcondition.point41}</li>
                        <ul className="ml-4 list-decimal">
                          <li>{termsandcondition.point42}</li>
                          <li>{termsandcondition.point43}</li>
                        </ul>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title10}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point44}</li>
                        <li>{termsandcondition.point45}</li>
                        <ul className="ml-4 list-decimal">
                          <li>{termsandcondition.point46}</li>
                          <li>{termsandcondition.point47}</li>
                          <li>{termsandcondition.point48}</li>
                        </ul>
                        <li>{termsandcondition.point49}</li>
                        <li>{termsandcondition.point50}</li>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title11}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point51}</li>
                        <li>{termsandcondition.point52}</li>
                        <li>{termsandcondition.point53}</li>
                        <li>{termsandcondition.point54}</li>
                        <li>{termsandcondition.point55}</li>
                        <li>{termsandcondition.point56}</li>
                        <li>{termsandcondition.point57}</li>
                        <li>{termsandcondition.point58}</li>
                        <li>{termsandcondition.point59}</li>
                        <li>{termsandcondition.point60}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    )
  }
}
